<template>
  <div class="form-group file-uploader">
    <label>
      <slot name="label">{{ label || 'Image' }}</slot>
    </label>
   <!-- <div class="d-flex justify-content-center">
      <div class="file-uploader-media">
        <b-img v-if="imgSrc" :src="imgSrc" :alt="label || 'Uploader Image'" />
        <div class="text-center" v-else>
          <strong>No Image Available</strong>
        </div>
      </div>
    </div>-->
    <div class="file-uploader-cta">
      <label :for="uploaderId" class="btn btn-uploader btn-block">
        <i class="icon-picture" /> {{ imgSrc ? 'Update Image' : 'Upload Image' }}
      </label>
      <b-form-file
        plain
        :id="uploaderId"
      />
    </div>
     <b-alert show variant="info" class="text-center my-2">
      Min. Image Dimension: 1500x600px<br />
      File Formats: .png, .jpg, .svg<br />
      Max. File Size: 2MB
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'BoUploader',
  props: {
    imgSrc: String,
    label: String
  },
  computed: {
    uploaderId() {
      return `uploader_${this.generateUniqueId()}`
    }
  },
  methods: {
    generateUniqueId() {
      return Math.random().toString(36).substring(2, 15)
    },
  }
}
</script>
