<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title>
        <div class="ml-2">
          <b-button variant="success" :to="{name:'KamusKeuangan'}" class="m-l-15">Posts List</b-button>
        </div>
      </bo-page-title>
      <template>
        <b-form @submit.prevent="handleSubmit">
          <b-tabs>
            <b-tab title="Credit Card Content">
              <b-card no-body>
                <b-card-body>
                  <b-row>
                    <b-col lg="8">
                      <b-row>
                        <b-col lg="12">
                          <b-form-group label-for="articleTitle">
                            <template #label>KTA Name<span class="text-danger mr5">*</span></template>
                            <b-form-input id="articleTitle" v-model="articleRow.title"
                              placeholder="e.g CIMB Niaga Wave n Go" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="8">
                          <b-form-group label-for="Credit Card Name">
                            <template #label>Minimal Plafon<span class="text-danger mr5">*</span></template>
                            <div class="input-group mb-3">
                              <span class="input-group-text" id="basic-addon1">Rp</span>
                              <input type="text" class="form-control" placeholder="90.000" aria-label="IuranTahunan"
                                aria-describedby="basic-addon1">
                            </div>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <b-form-group label-for="Credit Card Name">
                            <template #label>Suku Bunga Per Bulan<span class="text-danger mr5">*</span></template>
                            <div class="input-group mb-3">
                              <input type="text" class="form-control" placeholder="5" aria-label="IuranTahunan"
                                aria-describedby="basic-addon1">

                              <span class="input-group-text" id="basic-addon1">%</span>
                            </div>
                          </b-form-group>
                        </b-col>

                        <b-col lg="12">
                          <b-tabs pills card id="compare_info">
                            <b-tab title="Informasi" active>
                              <b-card-text>
                                <div class="row">
                                  <b-col lg="12">
                                    <b-form-group label-for="articleContent">
                                      <ckeditor :value="articleRow.content" />
                                    </b-form-group>
                                  </b-col>
                                </div>
                              </b-card-text>
                            </b-tab>
                             <b-tab title="Rincian Biaya">
                              <b-card-text>
                                <div class="row">
                                  <b-col lg="12">
                                    <b-form-group label-for="articleContent">
                                      <ckeditor :value="articleRow.content" />
                                    </b-form-group>
                                  </b-col>
                                </div>
                              </b-card-text>
                            </b-tab>
                               <b-tab title="Syarat &amp; Dokumen">
                              <b-card-text>
                                <div class="row">
                                  <b-col lg="12">
                                    <b-form-group label-for="articleContent">
                                      <ckeditor :value="articleRow.content" />
                                    </b-form-group>
                                  </b-col>
                                </div>
                              </b-card-text>
                            </b-tab>
                            <b-tab title="+ Add New">
                            </b-tab>
                          </b-tabs>
                        </b-col>

                        <b-col lg="12">
                          <b-form-group label-for="articleTitle">
                            <template #label>Slug<span class="text-danger mr5">*</span></template>
                            <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="e.g https://" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="9">
                          <b-form-group label-for="articleTags">
                            <template #label>Tags <span class="text-danger mr5">*</span></template>
                            <b-form-tags v-model="articleRow.tags" id="articleTags" />
                            <small>Separate with commas or the Enter key.
                            </small>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="4">
                      <div class="side_wrap">
                        <b-row>
                          <b-col lg="12" class="mb-2">
                            <bo-uploader :img-src="articleRow.thumbnail">
                              <template #label>Image <span class="text-danger ml5">*</span></template>
                            </bo-uploader>
                          </b-col>

                          <b-col lg="12" class="mb-2">
                            <b-form-group>
                              <label>Alt Image<span class="text-danger mr5">*</span></label>
                              <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Image title"
                                :formatter="formatter">
                              </b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col lg="12" class="mb-2">
                            <b-form-group label-for="articleAuthor">
                              <template #label>Author<span class="text-danger mr5">*</span></template>
                              <b-form-input id="articleAuthor" v-model="articleRow.author"
                                placeholder="e.g Administrator" />
                            </b-form-group>
                          </b-col>

                          <b-col lg="12" class="mb-2">
                            <b-form-group label-for="articlePubDate">
                              <template #label>Publish Date<span class="text-danger mr5">*</span></template>
                              <v-date-picker v-model="date">
                                <template v-slot="{ inputValue, inputEvents }">
                                  <input class="bg-white custom_inp px-2 py-1 rounded" :value="inputValue"
                                    v-on="inputEvents" />
                                </template>
                              </v-date-picker>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-tab>

            <b-tab title="SEO Settings">
              <b-card>
                <b-row>
                  <b-col lg="12">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoRow.title" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="9">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="4" id="homeMetaDesc" placeholder="Meta Description"
                        v-model="seoRow.description" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="9">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Tags
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete
                        tag-variant="success" tag-class="text-white" v-model="seoRow.keywords" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="7">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Canonical URL
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'A canonical URL is the URL of the page that Google thinks is most representative from a set of duplicate pages on your site.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="e.g https://duwitmu.com/" v-model="seoRow.title" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>

            <b-tab title="Ads Placement">
              <b-card no-body>
                <b-card-body>
                  <div class="row">
                    <div class="col-md-12" id="ads_placement">
                      <div class="mb_20">
                        <b-form-group label-for="articleTitle">
                          <template #label>Ads Device Type</template>
                          <b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
                            text-field="name" disabled-field="notEnabled">
                          </b-form-radio-group>
                        </b-form-group>
                      </div>
                      <b-tabs pills card vertical nav-wrapper-class="w-20" v-if="selected == 'A'" style="padding:0;">
                        <b-tab title="Top Ads" active>
                          <b-row>
                            <b-col cols="12">
                              <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Ads Name</th>
                                      <th scope="col">Ads Size</th>
                                      <th scope="col">Last Modified</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group>
                                          <b-form-checkbox switch id="activeAd" size="lg">
                                          </b-form-checkbox>
                                        </b-form-group>
                                      </td>
                                      <td>
                                        Ads #1
                                      </td>
                                      <td>728x90</td>
                                      <td>
                                        2021-11-03 13:52:00
                                      </td>
                                      <td>
                                        <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="7">
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Name</template>
                                <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="#ads 1" />
                              </b-form-group>
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Size<span class="text-danger mr5">*</span></template>
                                <v-select placeholer="Select Category" v-model="selected_ads_size"
                                  :options="ads_size" />
                              </b-form-group>
                            </b-col>
                            <b-col md="9">
                              <b-form-group label-for="articleTitle">
                                <template #label>Insert code<span class="text-danger mr5">*</span>
                                  <b-badge variant="info" pill class="help-badge"
                                    v-b-tooltip.hover.right="'Insert your adsense code'">
                                    <i class="fas fa-question"></i>
                                  </b-badge>
                                </template>
                                <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="5"
                                  max-rows="5">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group>
                                <b-form-checkbox switch id="activeAd" size="lg">Activate
                                  Ad?</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                        <b-tab title="Ads Sidebar #1">
                          <b-row>
                            <b-col cols="12">
                              <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Ads Name</th>
                                      <th scope="col">Ads Size</th>
                                      <th scope="col">Last Modified</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group>
                                          <b-form-checkbox switch id="activeAd" size="lg">
                                          </b-form-checkbox>
                                        </b-form-group>
                                      </td>
                                      <td>
                                        Ads #1
                                      </td>
                                      <td>728x90</td>
                                      <td>
                                        2021-11-03 13:52:00
                                      </td>
                                      <td>
                                        <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="7">
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Name</template>
                                <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="#ads 1" />
                              </b-form-group>
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Size<span class="text-danger mr5">*</span></template>
                                <v-select placeholer="Select Category" v-model="selected_ads_size"
                                  :options="ads_size" />
                              </b-form-group>
                            </b-col>
                            <b-col md="9">
                              <b-form-group label-for="articleTitle">
                                <template #label>Insert code<span class="text-danger mr5">*</span>
                                  <b-badge variant="info" pill class="help-badge"
                                    v-b-tooltip.hover.right="'Insert your adsense code'">
                                    <i class="fas fa-question"></i>
                                  </b-badge>
                                </template>
                                <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="5"
                                  max-rows="5">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group>
                                <b-form-checkbox switch id="activeAd" size="lg">Activate
                                  Ad?</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                        <b-tab title="Ads Sidebar #2">
                          <b-row>
                            <b-col cols="12">
                              <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Ads Name</th>
                                      <th scope="col">Ads Size</th>
                                      <th scope="col">Last Modified</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group>
                                          <b-form-checkbox switch id="activeAd" size="lg">
                                          </b-form-checkbox>
                                        </b-form-group>
                                      </td>
                                      <td>
                                        Ads #1
                                      </td>
                                      <td>728x90</td>
                                      <td>
                                        2021-11-03 13:52:00
                                      </td>
                                      <td>
                                        <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="7">
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Name</template>
                                <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="#ads 1" />
                              </b-form-group>
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Size<span class="text-danger mr5">*</span></template>
                                <v-select placeholer="Select Category" v-model="selected_ads_size"
                                  :options="ads_size" />
                              </b-form-group>
                            </b-col>
                            <b-col md="9">
                              <b-form-group label-for="articleTitle">
                                <template #label>Insert code<span class="text-danger mr5">*</span>
                                  <b-badge variant="info" pill class="help-badge"
                                    v-b-tooltip.hover.right="'Insert your adsense code'">
                                    <i class="fas fa-question"></i>
                                  </b-badge>
                                </template>
                                <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="5"
                                  max-rows="5">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group>
                                <b-form-checkbox switch id="activeAd" size="lg">Activate
                                  Ad?</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                      </b-tabs>

                      <b-tabs v-else pills card vertical nav-wrapper-class="w-20">
                        <b-tab title="Below Header">
                          <b-row>
                            <b-col cols="12">
                              <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Ads Name</th>
                                      <th scope="col">Ads Size</th>
                                      <th scope="col">Last Modified</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group>
                                          <b-form-checkbox switch id="activeAd" size="lg">
                                          </b-form-checkbox>
                                        </b-form-group>
                                      </td>
                                      <td>
                                        Ads #1
                                      </td>
                                      <td>728x90</td>
                                      <td>
                                        2021-11-03 13:52:00
                                      </td>
                                      <td>
                                        <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="7">
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Name</template>
                                <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="#ads 1" />
                              </b-form-group>
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Size<span class="text-danger mr5">*</span></template>
                                <v-select placeholer="Select Category" v-model="selected_ads_size"
                                  :options="ads_size" />
                              </b-form-group>
                            </b-col>
                            <b-col md="9">
                              <b-form-group label-for="articleTitle">
                                <template #label>Insert code<span class="text-danger mr5">*</span>
                                  <b-badge variant="info" pill class="help-badge"
                                    v-b-tooltip.hover.right="'Insert your adsense code'">
                                    <i class="fas fa-question"></i>
                                  </b-badge>
                                </template>
                                <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="5"
                                  max-rows="5">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group>
                                <b-form-checkbox switch id="activeAd" size="lg">Activate
                                  Ad?</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                        <b-tab title="Below Title">
                          <b-row>
                            <b-col cols="12">
                              <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Ads Name</th>
                                      <th scope="col">Ads Size</th>
                                      <th scope="col">Last Modified</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group>
                                          <b-form-checkbox switch id="activeAd" size="lg">
                                          </b-form-checkbox>
                                        </b-form-group>
                                      </td>
                                      <td>
                                        Ads #1
                                      </td>
                                      <td>728x90</td>
                                      <td>
                                        2021-11-03 13:52:00
                                      </td>
                                      <td>
                                        <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="7">
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Name</template>
                                <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="#ads 1" />
                              </b-form-group>
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Size<span class="text-danger mr5">*</span></template>
                                <v-select placeholer="Select Category" v-model="selected_ads_size"
                                  :options="ads_size" />
                              </b-form-group>
                            </b-col>
                            <b-col md="9">
                              <b-form-group label-for="articleTitle">
                                <template #label>Insert code<span class="text-danger mr5">*</span>
                                  <b-badge variant="info" pill class="help-badge"
                                    v-b-tooltip.hover.right="'Insert your adsense code'">
                                    <i class="fas fa-question"></i>
                                  </b-badge>
                                </template>
                                <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="5"
                                  max-rows="5">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group>
                                <b-form-checkbox switch id="activeAd" size="lg">Activate
                                  Ad?</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                        <b-tab title="Below Image Thumbnail">
                          <b-row>
                            <b-col cols="12">
                              <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Ads Name</th>
                                      <th scope="col">Ads Size</th>
                                      <th scope="col">Last Modified</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group>
                                          <b-form-checkbox switch id="activeAd" size="lg">
                                          </b-form-checkbox>
                                        </b-form-group>
                                      </td>
                                      <td>
                                        Ads #1
                                      </td>
                                      <td>728x90</td>
                                      <td>
                                        2021-11-03 13:52:00
                                      </td>
                                      <td>
                                        <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="7">
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Name</template>
                                <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="#ads 1" />
                              </b-form-group>
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Size<span class="text-danger mr5">*</span></template>
                                <v-select placeholer="Select Category" v-model="selected_ads_size"
                                  :options="ads_size" />
                              </b-form-group>
                            </b-col>
                            <b-col md="9">
                              <b-form-group label-for="articleTitle">
                                <template #label>Insert code<span class="text-danger mr5">*</span>
                                  <b-badge variant="info" pill class="help-badge"
                                    v-b-tooltip.hover.right="'Insert your adsense code'">
                                    <i class="fas fa-question"></i>
                                  </b-badge>
                                </template>
                                <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="5"
                                  max-rows="5">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group>
                                <b-form-checkbox switch id="activeAd" size="lg">Activate
                                  Ad?</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                        <b-tab title="Every 2 Paragraphs">
                          <b-row>
                            <b-col cols="12">
                              <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Ads Name</th>
                                      <th scope="col">Ads Size</th>
                                      <th scope="col">Last Modified</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group>
                                          <b-form-checkbox switch id="activeAd" size="lg">
                                          </b-form-checkbox>
                                        </b-form-group>
                                      </td>
                                      <td>
                                        Ads #1
                                      </td>
                                      <td>728x90</td>
                                      <td>
                                        2021-11-03 13:52:00
                                      </td>
                                      <td>
                                        <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="7">
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Name</template>
                                <b-form-input id="articleTitle" v-model="articleRow.title" placeholder="#ads 1" />
                              </b-form-group>
                              <b-form-group label-for="articleTitle">
                                <template #label>Ads Size<span class="text-danger mr5">*</span></template>
                                <v-select placeholer="Select Category" v-model="selected_ads_size"
                                  :options="ads_size" />
                              </b-form-group>
                            </b-col>
                            <b-col md="9">
                              <b-form-group label-for="articleTitle">
                                <template #label>Insert code<span class="text-danger mr5">*</span>
                                  <b-badge variant="info" pill class="help-badge"
                                    v-b-tooltip.hover.right="'Insert your adsense code'">
                                    <i class="fas fa-question"></i>
                                  </b-badge>
                                </template>
                                <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="5"
                                  max-rows="5">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group>
                                <b-form-checkbox switch id="activeAd" size="lg">Activate
                                  Ad?</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-tab>
          </b-tabs>
          <b-card no-body>
            <template #footer>
              <div class="text-right">
                <b-button class="" variant="default" @click="cancelAddUpdate">Cancel
                </b-button>
                <b-button class="ml-2" variant="light" @click="cancelAddUpdate">Save Draft
                </b-button>
                <b-button class="ml-2" variant="success" type="submit">Publish</b-button>
              </div>
            </template>
          </b-card>
        </b-form>
      </template>
    </b-container>
  </div>
</template>
<script>
  import BoUploader from '@/components/BoUploader.vue'
  import GlobalVue from '@/libs/Global.vue'

  export default {
    name: 'Article',
    extends: GlobalVue,
    components: {
      BoUploader,
    },

    mounted() {
      this.loadSeoData()
      this.loadArticleData()
    },

    computed: {
      dataSrc() {
        return this.dummyData.article
      },
    },

    methods: {
      loadSeoData() {
        let datas = this.dataSrc.seo
        this.seoRow = datas
      },
      loadArticleData() {
        let datas = this.dataSrc.articleList
        datas.forEach(el => {
          this.articleData.push(el)
        })
      }
    },
    data() {
      return {
        selected: 'A',
        ads_device: [{
            item: 'A',
            name: 'Desktop'
          },
          {
            item: 'B',
            name: 'Mobile'
          }
        ],
        date: new Date(),
        days: [],
        featured: 'not_accepted',
        seoRow: {},
        articleRow: {},
        articleData: [],
        rows: 30,
        perPage: 1,
        currentPage: 5,
        selected_category: 'All Category',
        category: [
          'All Category',
          'Pinjaman Online',
          'Asuransi',
          'Bank Digital',
          'Investasi',
          'Rencana Keuangan',
        ],
        selected_sort: 'Sort By',
        sort: [
          'Sort By',
          'Most Viewed',
          'Least Viewed',
          'Oldest',
          'Newest',
        ],
        selected_ads_size: 'Select Ads Size',
        ads_size: [
          'Select Ads Size',
          '300x250',
          '336x280',
          '728x90',
          '300x600',
          '320x100',
          '200x50',
          '320x20',
        ],
        selected_parent: ' ---Select Parent Catgeory ---',
        parent: [
          ' ---Select Parent Catgeory ---',
          'Asuransi',
          'Advetorial',
          'Pengeluaran',
          'Saham'
        ],
        selected_comment: 'Y',
        comment: [{
            item: 'Y',
            name: 'Yes'
          },
          {
            item: 'N',
            name: 'No'
          }
        ],
      }
    },
    watch: {
      $route() {
        if (!this.isList) {
          if (!this.isAdd) {
            this.articleRow = this.articleData.find(el => el.id == this.$route.params.slug)
            console.log(this.articleRow)
          } else {
            this.articleRow = {}
          }
        }
      }
    }
  }
</script>